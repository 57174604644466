// COLOR
// ---------------------------------------------------------------
$color: (
  green: #00eec4,
  darkGreen: #2ed47a,
  yellow: #ffb300,
  clay: #212240,
  content: #525f7f,
  blue: (
    light: #009ce4,
    base: #0075f8,
    dark: #253ba5,
  ),
  red: #dd3f4d,
);

@function color($keys...) {
  $map: $color;
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

// DURATION
// ---------------------------------------------------------------

$transitions: (
  sol: 0.3s,
  fast: 0.5s,
  default: 0.7s,
  slow: 1s,
);

@function time($speed) {
  @return map-get($transitions, $speed);
}

// EASE
// ---------------------------------------------------------------
$timing_functions: (
  inout: cubic-bezier(0.5, 0, 0, 1),
  fade: cubic-bezier(0, 0, 0.3, 1),
);

@function ease($type) {
  @return map-get($timing_functions, $type);
}
