.text-content {
  > * {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.7;
  }

  ul {
    position: relative;
    width: 100%;

    li {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.7;
      position: relative;
      width: 100%;
      padding-left: 15px;

      &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}
