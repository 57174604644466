.mts-tabs {
  background-color: $grey-superlight;
  padding: 1rem;
  border-radius: $mts-border-radius-lg;
  margin: 8px 0;

  &-nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $blue-darkest;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      list-style: none;
      text-align: center;
      cursor: pointer;
      transition: all 0.7s;
      color: $grey-dark;
      font-family: $mts-font-family;
      font-size: 16px;
      padding: 8px 16px;
    }

    .active-tab {
      position: relative;
      color: $blue-darkest;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        background-color: $blue-darkest;
      }
    }
  }

  &-content {
    margin: 8px 4px;
    font-size: 1rem;
    font-weight: 400;
    color: $grey-dark;
    line-height: 1.5;
  }
}
