// Colors

$gray: #f7f7f7;
$gray-dark: #525f7f;

$blue: #2a76f6;
$light-blue: #f3f7fe;

//Colors - new
$blue-darkest: #0e5feb;
$blue-dark: #338cff;
$blue-medium: #77b2ff;
$blue-branding: #a4cbfa;
$blue-light: #f3f7fe; //Light-blue

$green-superlight: #effefb;
$green-light: #e8fdf9;
$green: #11d6b1;
$green-dark: #008067;

$red-dark: #FF1F1F;
$red: #ff5555;
$red-light: #FF9999;
$red-superlight: #fef0f0;

$purple-dark: #620eeb;
$purple: #9051F5;
$purple-light: #C19FF9;
$purple-superlight: #f6efff;

$white: #ffffff;
$black: #000000;

$grey-dark: #5b5b5b;
$grey-medium: #848a97;
$grey: #d0d0d0;
$grey-light: #f4f4f4;
$grey-superlight: #fafafa;

$yellow-dark: #ffb800;
$yellow-light: #fff1ce;
$yellow-darkest: #74570e;
$yellow-superlight: #fffcf2;
// Font

// Could be useful
// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;

$mts-body-bg: #f8f8fb;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
$mts-font-size-base: 16px;
$mts-font-family: 'Montserrat', 'Lato', sans-serif;

// Border radius - Need to check some values later
$mts-border-radius-flat: 0;
$mts-border-radius-base: 0.25rem;
$mts-border-radius-lg: 0.5rem;
$mts-border-radius-xl: 1rem;
$mts-border-radius-pill: 10rem;
$mts-border-radius-circle: 50%;

// Sidebar

$mts-sidebar-width: 260px;
$mts-sidebar-padding: 0.5rem 1.5rem;
$mts-sidebar-background: $white;

$mts-sidebar-brand-padding: 1rem 0;
$mts-sidebar-brand-margin: 0 0 24px;
$mts-sidebar-brand-background: $white;

$mts-sidebar-option-padding: 0.75rem;
$mts-sidebar-option-margin: 0 0 0.5rem;
$mts-sidebar-option-border-radius: 8px;
$mts-sidebar-option-line-height: 1.5;
$mts-sidebar-option-color: $grey-dark;
$mts-sidebar-option-hover-background: $gray;
$mts-sidebar-option-hover-color: $gray-dark;
$mts-sidebar-option-active-color: $blue;
$mts-sidebar-option-active-background: $light-blue;

// Orgs Switcher

$mts-orgs-selector-margin: 0.125rem 0 0;
$mts-orgs-selector-padding: 0.5rem 0;
$mts-orgs-selector-color: $black;

$mts-orgs-selector-option-color: $black;
$mts-orgs-selector-option-background: $light-blue;
$mts-orgs-selector-option-margin: 0.125rem 0;
$mts-orgs-selector-option-padding: 0.25rem 1.25rem;

$mts-orgs-switcher-btn-background: $light-blue;
$mts-orgs-switcher-btn-padding: 0.5rem;

$mts-orgs-selector-menu-option-color: $white;
$mts-orgs-selector-menu-option-bg: $blue-darkest;
$mts-orgs-selector-menu-option-padding: 0.375rem 0.5rem;
