//Size - Default: 16px=1rem

.font-size-8px {
  font-size: 0.5rem !important;
}

.font-size-10px {
  font-size: 0.625rem !important;
}

.font-size-12px {
  font-size: 0.75rem !important;
}

.font-size-14px {
  font-size: 0.875rem !important;
}

.font-size-18px {
  font-size: 1.125rem !important;
}

.font-size-20px {
  font-size: 1.25rem !important;
}

.font-size-24px {
  font-size: 1.5rem !important;
}

.font-size-28px {
  font-size: 1.75rem !important;
}

.font-size-32px {
  font-size: 2rem !important;
}

.font-size-40px {
  font-size: 2.5rem !important;
}

//Weight - Default: 400=Regular
.font-light {
  font-weight: 300 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-black {
  font-weight: 900 !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-family-Lato {
  font-family: 'Lato', sans-serif;
}

.font-family-Montserrat {
  font-family: 'Montserrat', sans-serif;
}
