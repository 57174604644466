.bg-transparent {
  background-color: transparent !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-blue-darkest {
  background-color: $blue-darkest !important;
}

.bg-blue-dark {
  background-color: $blue-dark !important;
}

.bg-blue-medium {
  background-color: $blue-medium !important;
}

.bg-blue-branding {
  background-color: $blue-branding !important;
}

.bg-blue-light {
  background-color: $blue-light !important;
}

.bg-green-superlight {
  background-color: $green-superlight !important;
}

.bg-green-light {
  background-color: $green-light !important;
}

.bg-green {
  background-color: $green !important;
}

.bg-green-dark {
  background-color: $green-dark !important;
}

.bg-red {
  background-color: $red !important;
}

.bg-red-superlight {
  background-color: $red-superlight !important;
}

.bg-purple-dark {
  background-color: $purple-dark !important;
}

.bg-purple-superlight {
  background-color: $purple-superlight !important;
}

.bg-grey-dark {
  background-color: $grey-dark !important;
}

.bg-grey-medium {
  background-color: $grey-medium !important;
}

.bg-grey {
  background-color: $grey !important;
}

.bg-grey-light {
  background-color: $grey-light !important;
}

.bg-grey-superlight {
  background-color: $grey-superlight !important;
}

.bg-yellow-dark {
  background-color: $yellow-dark !important;
}

.bg-yellow-darkest {
  background-color: $yellow-darkest !important;
}

.bg-yellow-superlight {
  background-color: $yellow-superlight !important;
}

.bg-yellow-light {
  background-color: $yellow-light !important;
}
