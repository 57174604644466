@import 'sass/vars';

.screen-progress {
  background-color: color(blue, base);
  color: white;
  text-align: center;
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 140;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top time(default) ease(inout),
    opacity time(default) ease(fade);
  box-shadow: 0 5px 10px fade-out(black, 0.9);

  &.open {
    margin-top: 0;
    opacity: 1;
  }

  .screen-message {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.7;
    margin: 0;
    margin-bottom: 15px;
  }

  .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;

    @keyframes progress_spinner {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      50% {
        opacity: 0.3;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }

    span {
      background-color: white;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }
    }
  }
}
