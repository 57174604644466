.text-black {
  color: $black !important;
}

.text-white {
  color: $white !important;
}

.text-blue {
  color: $blue !important;
}

.text-blue-darkest {
  color: $blue-darkest !important;
}

.text-blue-dark {
  color: $blue-dark !important;
}

.text-blue-medium {
  color: $blue-medium !important;
}

.text-blue-branding {
  color: $blue-branding !important;
}

.text-blue-light {
  color: $blue-light !important;
}

.text-green-superlight {
  color: $green-superlight !important;
}

.text-green {
  color: $green !important;
}

.text-green-dark {
  color: $green-dark !important;
}

.text-red {
  color: $red !important;
}

.text-red-superlight {
  color: $red-superlight !important;
}

.text-purple-dark {
  color: $purple-dark !important;
}

.text-purple-superlight {
  color: $purple-superlight !important;
}

.text-grey-dark {
  color: $grey-dark !important;
}

.text-grey-medium {
  color: $grey-medium !important;
}

.text-grey {
  color: $grey !important;
}

.text-grey-light {
  color: $grey-light !important;
}

.text-grey-superlight {
  color: $grey-superlight !important;
}

.text-yellow-dark {
  color: $yellow-dark !important;
}

.text-yellow-darkest {
  color: $yellow-darkest !important;
}

.text-yellow-superlight {
  color: $yellow-superlight !important;
}

.text-yellow-light {
  color: $yellow-light !important;
}
