.mts-autocomplete-container {
  display: block;
  position: relative;
}

.mts-autocomplete {
  box-sizing: border-box;
  margin-top: 4px;
  padding: 0.125rem;
  background-color: darken($white, 2%);
  position: absolute;
  width: 100%;
  border-radius: 8px;
  z-index: 1000;
  height: auto;

  &-list {
    border-radius: 8px;
    border-top-width: 0;
    list-style: none;
    font-family: $mts-font-family;
    margin: 4px 0;
    max-height: 150px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;

    > .active {
    }

    > li {
      color: $blue-darkest;
      background-color: $white;
      border-radius: $mts-border-radius-lg;
      list-style-type: none;
      padding: 0.5rem 1rem;
      margin-bottom: 0.125rem;
      cursor: pointer;

      &.large {
        padding: 0.75rem 1.25rem !important;
        font-size: 1.25rem !important;
        margin-bottom: 0.25rem !important;
      }
      &:hover {
        background-color: $blue-darkest;
        color: $white;
      }
    }
  }

  .no-autocomplete {
    font-family: $mts-font-family;
    font-weight: 500;
    padding: 0.5rem;
    margin: 0.25rem 0;
    border-radius: $mts-border-radius-lg;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: $grey-light;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $blue-darkest;
    border-radius: 10em;
    border: 2px solid #eaeaea;
  }
}
