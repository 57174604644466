@import 'reset';

/** Body. */
body {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }
}

/** Layout. */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

.container-logged {
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

.select-list {
  z-index: 5;
}

.mento-box {
  border-radius: 6px;
  overflow: hidden;
  padding: 35px 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.mento-box-title {
  margin-top: 20px;
  border-bottom: 1px solid #a0a1b3;
  margin-bottom: 40px;

  span {
    color: #0075f8;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.01em;
    display: inline-block;
    position: relative;
    padding: 0 5px 9px;
  }
}

/** Elements. */
@import 'elements/texts';
@import 'elements/progress-screen';
@import 'elements/horizontal-checklist.scss';
