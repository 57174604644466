// Temporary
.mts-list-title {
  color: #5b5b5b;
  margin: 1rem 0;
}

.mts-page-header {
  width: 100%;
  margin: 124px 0 20px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: $blue-darkest;
  color: $white;

  &-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 8px 0;
  }

  &-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
  }
}

//margin
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}

.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}

//borders
.border-bottom {
  border-bottom: 1px solid $grey-light;
}

//Border Radius
.rounded {
  border-radius: $mts-border-radius-lg !important;
}

.flat {
  border-radius: $mts-border-radius-flat !important;
}

.rounded-base {
  border-radius: $mts-border-radius-base !important;
}

.rounded-lg {
  border-radius: $mts-border-radius-lg !important;
}

.rounded-top-lg {
  border-top-left-radius: $mts-border-radius-lg !important;
  border-top-right-radius: $mts-border-radius-lg !important;
}

.rounded-circle {
  border-radius: $mts-border-radius-circle !important;
}

.rounded-pill {
  border-radius: $mts-border-radius-pill !important;
}

//Border color
.border-blue-darkest {
  border-color: $blue-darkest !important;
}


//Background Colors

//Displays
.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-grid {
  display: grid !important;
}

//Flex direction
.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

//justify content
.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

//Align-items
.align-items-center {
   align-items: center !important; 
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

//Align content
.align-content-center {
  align-content: center !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

//Align Self
.align-self-auto {
  align-self: auto !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.flex-1 {
  flex: 1;
}

//dot

.dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  background-color: #000000;
}

.dot-10px {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  background-color: #000000;
}

// text
.text-align-center {
  text-align: center !important;
}

//input
.mts-legacy-input {
  font-family: 'Montserrat';
  color: #9b9caf;
  font-size: 16px;
  font-weight: normal;
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  border: 2px solid #a0a1b3;
  border-radius: 60px;
  outline: none;
  padding: 0 20px;
}

.video {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 13px;
  overflow: hidden;

  &-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    text-indent: -9999px;
  }

  &-image {
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.6;
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    svg {
      transform-origin: 50% 50%;
      transform: scale(0.9);
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

// events
.on-hover-pointer {
  cursor: pointer;
}
