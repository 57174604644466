.mts-orgs-switcher {
  display: inline-flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 2rem;

  .mts-orgs-selector {
    width: 100%;
    background-clip: padding-box;
    border: none;
    color: $mts-orgs-selector-color;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    list-style: none;
    margin: $mts-orgs-selector-margin;
    padding: $mts-orgs-selector-padding;
    position: absolute;
    text-align: left;
    bottom: 56px;
    transition: all 0.3s ease-in;

    &-option {
      text-decoration: none;
      font-size: 12px;
      color: $mts-orgs-selector-option-color;
      background-color: $mts-orgs-selector-option-background;
      padding: $mts-orgs-selector-option-padding;
      margin: $mts-orgs-selector-option-margin;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 8px;

      &:hover {
        background-color: darken($mts-orgs-selector-option-background, 2.5%);
      }
    }

    &-avatar {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 8px;
    }
  }

  .mts-orgs-selector-contrast {
    bottom: 0 !important;
    top: 24px;
    .mts-orgs-selector {
      &-option {
        color: $white !important;
        background-color: $blue-darkest !important;
        padding: 0.25rem 0.5rem;

        &:hover {
          background-color: lighten($blue-darkest, 2.5%);
        }
      }
    }
  }

  .mts-orgs-selector-menu {
    width: 100%;
    background-clip: padding-box;
    border: none;
    color: $mts-orgs-selector-color;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    list-style: none;
    margin: $mts-orgs-selector-margin;
    padding: $mts-orgs-selector-padding;
    position: absolute;
    text-align: center;
    top: 24px;
    transition: all 0.3s ease-in;
    z-index: 50;

    &-option {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      color: $mts-orgs-selector-menu-option-color;
      background-color: $mts-orgs-selector-menu-option-bg;
      padding: $mts-orgs-selector-menu-option-padding;
      margin: $mts-orgs-selector-option-margin;
      cursor: pointer;
      border-radius: $mts-border-radius-lg;
      text-align: left;
      transition: all ease-in-out 0.2s;
      min-width: 120px;
      &:hover {
        background-color: lighten($mts-orgs-selector-menu-option-bg, 2.5%);
      }
    }

    &-avatar {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 8px;
    }
  }
}

.mts-orgs-switcher-btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $mts-orgs-switcher-btn-padding;
  background-color: $mts-orgs-switcher-btn-background;
  border-radius: 8px;
  cursor: pointer;

  &-logo {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 1rem;
    margin-right: 8px;
  }

  &-info {
    display: flex;
    flex-direction: column;

    &-username {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    &-message {
      font-size: 12px;
    }
  }

  &-icon {
    vertical-align: middle;
    transition: all ease-in-out 0.2s;
  }

  .icon-rotate {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: darken($mts-orgs-switcher-btn-background, 2.5%);
  }
}

.mts-orgs-switcher-btn-alternative {
  background-color: $blue-darkest;
  border-radius: $mts-border-radius-pill;
  color: #ffffff;
  border: 2px solid $blue-darkest;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: darken($blue-darkest, 5%);
  }
}
