@import 'mtsAssets/scss/_variables';

.mts-cookies-pop-up {
  background: linear-gradient(45deg, fade-out($purple-dark, 0.1) 0%, fade-out($red, 0.1) 60%);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 500;
  height: 80px;
  vertical-align: middle;

  button, a {
    color: white;
    border-color: white;
  }

  .mts-btn-primary {
    background-color: $purple-dark;
    border: none;
    font-weight: bold;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }
}