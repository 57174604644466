.mts-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-16 {
    width: 16px;
    height: 16px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-20 {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-24 {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-28 {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-32 {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-40 {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-48 {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: $mts-border-radius-lg;
  }

  &-72 {
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: $mts-border-radius-xl;
  }

  &-112 {
    width: 112px;
    height: 112px;
    object-fit: cover;
    border-radius: $mts-border-radius-xl;
  }

  &-name {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 700;
    color: $grey-dark;
  }

  &-info {
    margin-left: 8px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &-name {
      font-size: 16px;
      font-weight: 700;
      color: $grey-dark;
    }
    
    &-address {
      font-size: 12px;
      color: $grey-medium;
    }
  }
}
